<template>
    <div>

        <div class="game_type_icon">
            <img v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_SOCCER"
                 src="../../../assets/images/left/icon/football-ico.png">
            <img v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL"
                 src="../../../assets/images/left/icon/basketball-ico.png">
            <img v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_BASEBALL"
                 src="../../../assets/images/left/icon/baseball-ico.png">
            <img v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL"
                 src="../../../assets/images/left/icon/volleyball-ico.png">
        </div>

        <h4>
            {{gameInfo.startTime|datef('MM/DD HH:mm')}}
        </h4>
        <h4>
            <img alt="" :src="gameInfo.league.imageUrl" class="league_img"> {{gameInfo.league.leagueName}}
        </h4>
        <div class="panel">
            <div class="home">
                <div class="team_image_home">
                    <img src="../../../assets/images/temp/team1.png">
                </div>
                <div class="team_name_home">
                    {{gameInfo.homeTeamName}}
                </div>
            </div>
            <div class="score">
                <span>{{gameInfo.homeScore}}</span>
            </div>
            <div class="race_status">
                        <span class="sec">
                            <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_SOCCER && gameInfo.halfStatus === 1">1 half</i>
                            <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_SOCCER && gameInfo.halfStatus === 2">2 half</i>
                            <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_BASEBALL">{{gameInfo.halfStatus + ' Inning'}}</i>
                            <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{gameInfo.halfStatus + 'Q'}}</i>
                            <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_VOLLEYBALL">{{gameInfo.halfStatus + ' Set'}}</i>
                        </span>
                <span class="t">
                    <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_SOCCER">{{gameInfo.timeMm + '\''}}</i>
                     <i v-if="gameInfo.apiTypeId === inplayConst.SPORTS_ID_BASKETBALL">{{gameInfo.timeMm + ':' + gameInfo.timeSs}}</i>
                 </span>
            </div>
            <div class="score">
                <span>{{gameInfo.awayScore}}</span>
            </div>
            <div class="away">
                <div class="team_image_away">
                    <img src="../../../assets/images/temp/team1.png">
                </div>
                <div class="team_name_away">
                    {{gameInfo.awayTeamName}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import inplayConst from "../../../common/inplayConst";

    export default {
        name: "InplayInfo",
        props: {
            gameInfo: {
                type: Object,
                default() {
                    return null
                }
            },
        },
        data() {
            return {
                inplayConst,
            }
        }
    }
</script>

<style scoped>

    .inplay_game .game_type_icon {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .inplay_game .game_type_icon img {
        width: 40px;
    }
    .inplay_game  .league_img {
        width: 28px;
    }

    .inplay_game h4 {
        text-align: center;
        font-size: 12px !important;
        font-weight: normal;
        height: 28px;
        line-height: 28px;
    }

    .inplay_game .panel {
        width: 100%;
    }

    .inplay_game .panel img {
        width: 40px
    }

    .inplay_game .home {
        display: inline-block;
        width: 30%;
    }

    .inplay_game .away {
        display: inline-block;
        width: 30%;
    }

    .inplay_game .race_status {
        display: inline-block;
        width: 20%;
        height: 60px;
        text-align: center;
        vertical-align: top;
    }

    .inplay_game .race_status span {
        display: block;
        line-height: 30px;
    }

    .inplay_game .score {
        display: inline-block;
        width: 8%;
        text-align: center;
        vertical-align: top;
        line-height: 60px;
        font-weight: bold;
        color: #f4c600;
        font-size: 16px;
    }
    .inplay_game .panel .team_name_home,.team_name_away{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .inplay_game .panel .team_name_home {
        text-align: right;
        line-height: 28px;
    }

    .inplay_game .panel .team_image_home {
        text-align: right;
    }

    .inplay_game .panel .team_name_away {
        text-align: left;
        line-height: 28px;
    }

    .inplay_game .panel .team_image_away {
        text-align: left;
    }

    .inplay_game i {
        font-style: normal;
    }
</style>